import React from 'react'
import Seo from '../components/seo'

const Treatments = () => {
    return (
        <>
            Treatments
        </>
    )
}

export default Treatments

export const Head = () => {
    return (
        <>
            <Seo
                pageTitle="Treatment Options"
                pageDescription="Essence of Beauty Treatment Options"
                pageKeywords="Holistic, Beauty, Organic, Treatments, Peels, Ottawa, Skin, Acne, Beauty, Spa"
                pageUrl="https://www.essenceofbeauty.ca/treatments/"
                pageImage="https://github.com/brad-adrenalize/eob/blob/main/src/assets/images/meet-eva.png?raw=true"
            />
            <link rel="canonical" href="https://www.essenceofbeauty.ca/treatments/" />
        </>
    )
} 